<template>
  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.9743 24.9955V33.0712C44.9743 33.7827 44.1994 34.2311 43.5853 33.8753L29.5978 25.8045C28.9788 25.4487 28.9788 24.5568 29.5978 24.1962L43.5853 16.1253C44.2042 15.7695 44.9743 16.2131 44.9743 16.9295V25.0052V24.9955Z"
      fill="white"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.25 13.6288C4.25 12.5445 5.06229 11.75 5.96352 11.75H33.0365C33.9377 11.75 34.75 12.5445 34.75 13.6288V36.3712C34.75 37.4555 33.9377 38.25 33.0365 38.25H5.96352C5.06229 38.25 4.25 37.4555 4.25 36.3712V13.6288Z"
      fill="white"
      stroke="black"
      stroke-width="1.5"
    />
    <path
      d="M26.8935 29.5C26.3673 29.5 25.8608 29.4136 25.3739 29.2408C24.8948 29.0681 24.4707 28.8128 24.1016 28.4751C23.7404 28.1374 23.4498 27.7173 23.2299 27.2147C23.01 26.7042 22.9 26.1152 22.9 25.4476V24.5641C22.9 23.8966 23.01 23.3115 23.2299 22.8089C23.4498 22.2984 23.7404 21.8743 24.1016 21.5366C24.4707 21.1911 24.8948 20.9319 25.3739 20.7592C25.8608 20.5864 26.3673 20.5 26.8935 20.5H26.9053C27.4315 20.5 27.9262 20.5864 28.3896 20.7592C28.8608 20.9241 29.2731 21.1597 29.6265 21.466C29.9799 21.7723 30.2626 22.1453 30.4747 22.5851C30.6867 23.017 30.8045 23.5039 30.8281 24.0458H29.2377C29.2142 23.7081 29.1357 23.4058 29.0021 23.1387C28.8686 22.8639 28.6959 22.6361 28.4838 22.4555C28.2796 22.2749 28.0401 22.1374 27.7652 22.0432C27.4982 21.9411 27.2155 21.8901 26.9171 21.8901H26.9053C26.6069 21.8901 26.3163 21.945 26.0336 22.055C25.7508 22.1571 25.4995 22.3141 25.2796 22.5262C25.0597 22.7304 24.883 22.9895 24.7495 23.3037C24.6239 23.6099 24.561 23.9673 24.561 24.3757V25.6361C24.561 26.0445 24.6239 26.4058 24.7495 26.7199C24.883 27.0262 25.0597 27.2853 25.2796 27.4974C25.4995 27.7016 25.7508 27.8586 26.0336 27.9686C26.3163 28.0707 26.6069 28.1217 26.9053 28.1217H26.9171C27.2155 28.1217 27.4982 28.0746 27.7652 27.9804C28.0401 27.8783 28.2796 27.7369 28.4838 27.5563C28.6959 27.3757 28.8686 27.1518 29.0021 26.8848C29.1357 26.6178 29.2142 26.3115 29.2377 25.966H30.8281C30.8045 26.5 30.6867 26.9869 30.4747 27.4267C30.2626 27.8586 29.9799 28.2277 29.6265 28.534C29.2731 28.8403 28.8608 29.0798 28.3896 29.2526C27.9262 29.4175 27.4315 29.5 26.9053 29.5H26.8935Z"
      fill="black"
    />
    <path
      d="M13.3066 29.3115L11.4571 26.284H9.94926V29.3115H8.30005V20.6885H11.5514C12.0069 20.6885 12.427 20.7474 12.8118 20.8652C13.1966 20.9751 13.5265 21.1479 13.8014 21.3835C14.0841 21.6191 14.3 21.9175 14.4493 22.2788C14.6063 22.6322 14.6849 23.0484 14.6849 23.5275V23.6335C14.6849 24.2461 14.5474 24.7487 14.2726 25.1414C13.9977 25.5262 13.6207 25.8167 13.1417 26.0131L15.1914 29.3115H13.3066ZM9.94926 22.0667V25.1531H11.5396C12.0029 25.1531 12.3681 25.0353 12.6351 24.7997C12.9021 24.5563 13.0357 24.1754 13.0357 23.6571V23.5628C13.0357 23.0445 12.9021 22.6675 12.6351 22.4319C12.3681 22.1885 12.0029 22.0667 11.5396 22.0667H9.94926Z"
      fill="black"
    />
    <path
      d="M17.8511 27.9568H21.9388V29.3115H16.2372V20.6885H21.8092V22.0667H17.8511V24.3521H21.3027V25.6597H17.8511V27.9568Z"
      fill="black"
    />
  </svg>
</template>
