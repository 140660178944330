<template>
  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_2798_329" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1699 7C8.03851 7 5.5 9.71345 5.5 13.0607V32.9394C5.5 36.2866 8.03851 39 11.1699 39H13.3619L11.3655 47.0001L25.08 39H39.8301C42.9615 39 45.5 36.2866 45.5 32.9393V13.0607C45.5 9.71345 42.9615 7 39.8301 7H11.1699Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1699 7C8.03851 7 5.5 9.71345 5.5 13.0607V32.9394C5.5 36.2866 8.03851 39 11.1699 39H13.3619L11.3655 47.0001L25.08 39H39.8301C42.9615 39 45.5 36.2866 45.5 32.9393V13.0607C45.5 9.71345 42.9615 7 39.8301 7H11.1699Z"
      fill="white"
    />
    <path
      d="M13.3619 39L14.8173 39.3632L15.2822 37.5H13.3619V39ZM11.3655 47.0001L9.91011 46.6369L9.0489 50.088L12.1213 48.2958L11.3655 47.0001ZM25.08 39V37.5H24.6744L24.3242 37.7043L25.08 39ZM7 13.0607C7 10.4461 8.95953 8.5 11.1699 8.5V5.5C7.11749 5.5 4 8.98075 4 13.0607H7ZM7 32.9394V13.0607H4V32.9394H7ZM11.1699 37.5C8.95953 37.5 7 35.5539 7 32.9394H4C4 37.0193 7.11749 40.5 11.1699 40.5V37.5ZM13.3619 37.5H11.1699V40.5H13.3619V37.5ZM12.8208 47.3633L14.8173 39.3632L11.9065 38.6368L9.91011 46.6369L12.8208 47.3633ZM24.3242 37.7043L10.6097 45.7044L12.1213 48.2958L25.8358 40.2957L24.3242 37.7043ZM39.8301 37.5H25.08V40.5H39.8301V37.5ZM44 32.9393C44 35.5539 42.0405 37.5 39.8301 37.5V40.5C43.8825 40.5 47 37.0192 47 32.9393H44ZM44 13.0607V32.9393H47V13.0607H44ZM39.8301 8.5C42.0405 8.5 44 10.4461 44 13.0607H47C47 8.98075 43.8825 5.5 39.8301 5.5V8.5ZM11.1699 8.5H39.8301V5.5H11.1699V8.5Z"
      fill="black"
      mask="url(#path-1-inside-1_2798_329)"
    />
    <path
      d="M20.0999 18V21"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M30.9009 18V21"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M18.3162 26C19.9185 28.2026 22.5414 29.6406 25.5 29.6406C28.4585 29.6406 31.0814 28.2026 32.6837 26"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
