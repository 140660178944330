<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2592 41.2966V33.8892C24.2592 33.4801 24.5909 33.1484 25 33.1484C25.4091 33.1484 25.7407 33.4801 25.7407 33.8892V41.2966C25.7407 41.7057 25.4091 42.0373 25 42.0373C24.5909 42.0373 24.2592 41.7057 24.2592 41.2966Z"
      fill="black"
    />
    <path
      d="M13.8889 42.0371H36.1111"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M8 8.75H42C43.2426 8.75 44.25 9.75736 44.25 11V33C44.25 34.2426 43.2426 35.25 42 35.25H8C6.75736 35.25 5.75 34.2426 5.75 33V11C5.75 9.75736 6.75736 8.75 8 8.75Z"
      fill="white"
      stroke="black"
      stroke-width="1.5"
    />
    <path
      d="M30.3704 28.27V21.77C30.3704 21.3558 30.7061 21.02 31.1204 21.02C31.5346 21.02 31.8704 21.3558 31.8704 21.77V28.27C31.8704 28.6842 31.5346 29.02 31.1204 29.02C30.7061 29.02 30.3704 28.6842 30.3704 28.27Z"
      fill="black"
    />
    <path
      d="M25.8704 28.27V13.77C25.8704 13.3558 26.2061 13.02 26.6204 13.02C27.0346 13.02 27.3704 13.3558 27.3704 13.77V28.27C27.3704 28.6842 27.0346 29.02 26.6204 29.02C26.2061 29.02 25.8704 28.6842 25.8704 28.27Z"
      fill="black"
    />
    <path
      d="M21.3704 28.27V17.77C21.3704 17.3558 21.7061 17.02 22.1204 17.02C22.5346 17.02 22.8704 17.3558 22.8704 17.77V28.27C22.8704 28.6842 22.5346 29.02 22.1204 29.02C21.7061 29.02 21.3704 28.6842 21.3704 28.27Z"
      fill="black"
    />
    <path
      d="M16.8704 28.27V21.77C16.8704 21.3558 17.2061 21.02 17.6204 21.02C18.0346 21.02 18.3704 21.3558 18.3704 21.77V28.27C18.3704 28.6842 18.0346 29.02 17.6204 29.02C17.2061 29.02 16.8704 28.6842 16.8704 28.27Z"
      fill="black"
    />
  </svg>
</template>
