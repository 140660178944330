<template>
  <svg
    width="220"
    height="220"
    viewBox="0 0 220 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.3778 160.5H82H130.533H162.889C180.343 160.5 194.5 146.462 194.5 129.136C194.5 111.804 180.029 97.7716 162.581 97.7716C160.724 97.7716 158.9 97.9274 157.123 98.2267C157.152 97.6279 157.167 97.0255 157.167 96.4198C157.167 76.0258 140.503 59.5 119.956 59.5C105.383 59.5 92.7659 67.8115 86.6591 79.9183C82.8545 77.9172 78.5154 76.784 73.9111 76.784C58.862 76.784 46.6556 88.8879 46.6556 103.827C46.6556 104.548 46.684 105.262 46.7397 105.968C34.5507 108.922 25.5 119.83 25.5 132.84C25.5 148.12 37.9851 160.5 53.3778 160.5Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M87 118.111C91.7893 124.163 100.155 128.18 109.656 128.18C119.157 128.18 127.523 124.163 132.312 118.111"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M99.0833 102V108.042" stroke="black" stroke-linecap="round" />
    <path d="M119.222 102V108.042" stroke="black" stroke-linecap="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41 29.5918L59 23L77 29.5918C77 29.5918 77 30.4241 77 41.6406C77 52.8571 59 61 59 61C59 61 41 52.8571 41 41.6406C41 30.4241 41 29.5918 41 29.5918Z"
      fill="#4570F5"
      stroke="black"
    />
    <circle cx="151.5" cy="163.5" r="26" fill="#4570F5" stroke="black" />
    <path
      d="M141 153.5H162C163.381 153.5 164.5 154.619 164.5 156V170C164.5 171.381 163.381 172.5 162 172.5H141C139.619 172.5 138.5 171.381 138.5 170V156C138.5 154.619 139.619 153.5 141 153.5Z"
      fill="#FFE100"
      stroke="black"
    />
    <path d="M144 178H159" stroke="black" stroke-linecap="round" />
    <path
      d="M157 166.688V163.688C157 163.412 157.224 163.188 157.5 163.188C157.776 163.188 158 163.412 158 163.688V166.688C158 166.965 157.776 167.188 157.5 167.188C157.224 167.188 157 166.965 157 166.688Z"
      fill="black"
    />
    <path
      d="M153 166.688V159.688C153 159.412 153.224 159.188 153.5 159.188C153.776 159.188 154 159.412 154 159.688V166.688C154 166.965 153.776 167.188 153.5 167.188C153.224 167.188 153 166.965 153 166.688Z"
      fill="black"
    />
    <path
      d="M149 166.688V161.688C149 161.412 149.224 161.188 149.5 161.188C149.776 161.188 150 161.412 150 161.688V166.688C150 166.965 149.776 167.188 149.5 167.188C149.224 167.188 149 166.965 149 166.688Z"
      fill="black"
    />
    <path
      d="M145 166.688V163.688C145 163.412 145.224 163.188 145.5 163.188C145.776 163.188 146 163.412 146 163.688V166.688C146 166.965 145.776 167.188 145.5 167.188C145.224 167.188 145 166.965 145 166.688Z"
      fill="black"
    />
    <path
      d="M151 177.5V172.5C151 172.224 151.224 172 151.5 172C151.776 172 152 172.224 152 172.5V177.5C152 177.776 151.776 178 151.5 178C151.224 178 151 177.776 151 177.5Z"
      fill="black"
    />
    <path d="M51 40L57 46L68.5 34.5" stroke="black" />
    <path
      d="M156.352 47.224C167.329 52.5526 183.544 69.0838 166.989 66.2882C172.941 69.5474 182.352 79.2975 172.739 77.711"
      stroke="black"
      stroke-linecap="round"
    />
    <path
      d="M148.642 28.7515L148.519 33.4268L153.173 32.9553L150.325 36.6656L154.367 39.0191L149.883 40.3473L151.769 44.6269L147.36 43.0655L146.371 47.6366L143.722 43.7821L140.235 46.8986L140.357 42.2233L135.704 42.6949L138.552 38.9845L134.51 36.631L138.994 35.3029L137.108 31.0233L141.517 32.5846L142.506 28.0136L145.155 31.868L148.642 28.7515Z"
      fill="white"
      stroke="black"
      stroke-linejoin="round"
    />
  </svg>
</template>
