<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25" cy="25" r="19.25" stroke="black" stroke-width="1.5" />
    <path
      d="M36.25 25C36.25 30.4103 34.9327 35.2721 32.8422 38.7563C30.7427 42.2554 27.9468 44.25 25 44.25C22.0532 44.25 19.2573 42.2554 17.1578 38.7563C15.0673 35.2721 13.75 30.4103 13.75 25C13.75 19.5897 15.0673 14.7279 17.1578 11.2437C19.2573 7.74461 22.0532 5.75 25 5.75C27.9468 5.75 30.7427 7.74461 32.8422 11.2437C34.9327 14.7279 36.25 19.5897 36.25 25Z"
      stroke="black"
      stroke-width="1.5"
    />
    <path d="M6 25H45" stroke="black" stroke-width="1.5" />
    <path d="M8 15H42" stroke="black" stroke-width="1.5" />
    <path d="M8 35H42" stroke="black" stroke-width="1.5" />
    <path d="M25 45L25 5" stroke="black" stroke-width="1.5" />
  </svg>
</template>
