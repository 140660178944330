<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.25"
      y="9.25"
      width="41.5"
      height="13.5"
      rx="2.75"
      stroke="black"
      stroke-width="1.5"
    />
    <circle cx="39" cy="16" r="2.25" stroke="black" stroke-width="1.5" />
    <circle cx="31" cy="16" r="2.25" stroke="black" stroke-width="1.5" />
    <path d="M8 13V19" stroke="black" stroke-width="1.5" />
    <path d="M12 13V19" stroke="black" stroke-width="1.5" />
    <path d="M16 13V19" stroke="black" stroke-width="1.5" />
    <rect
      x="4.25"
      y="27.25"
      width="41.5"
      height="13.5"
      rx="2.75"
      stroke="black"
      stroke-width="1.5"
    />
    <circle cx="39" cy="34" r="2.25" stroke="black" stroke-width="1.5" />
    <circle cx="31" cy="34" r="2.25" stroke="black" stroke-width="1.5" />
    <path d="M8 31V37" stroke="black" stroke-width="1.5" />
    <path d="M12 31V37" stroke="black" stroke-width="1.5" />
    <path d="M16 31V37" stroke="black" stroke-width="1.5" />
  </svg>
</template>
