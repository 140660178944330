<template>
  <h3>지원 환경 상세</h3>
  <table>
    <caption class="screen-out">
      항목별 상세 내용
    </caption>
    <thead>
      <tr>
        <th>항목</th>
        <th>상세 내용</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>오디오 프로파일</td>
        <td>
          <ul>
            <li>모노 및 스테레오 타입 제공</li>
            <li>16kHz ~ 48kHz</li>
            <li>코덱 : Opus</li>
            <li>모드 : 음악 청취 모드 / 음성(발화) 모드 제공 (예정)</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>비디오 프로파일</td>
        <td>
          <ul>
            <li>코덱 : H.264, VP8, VP9, AV1</li>
            <li>화질 : 권장 480p ~ 1080p (최대 4K 지원 가능)</li>
            <li>FPS : 권장 30FPS</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>동시 접속</td>
        <td>
          <ul>
            <li>
              최대 동시 접속
              <ol>
                <li>
                  1) 화상회의 권장 : 최대 200 ~ 500명 (영상 Bitrate 및 서비스
                  유형에 따라 다를 수 있음)
                </li>
                <li>2) 음성회의 권장 : 최대 500 ~ 1,000명</li>
                <li>
                  3) 방송 권장 : Low Latency의 경우 최대 2,000명 이하 (초과
                  대규모 방송시 기술 가이드를 위한 별도 상담 필요)
                </li>
              </ol>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>접속 성공률</td>
        <td>
          <ul>
            <li>국내 : 99% 이상 접속 성공률</li>
            <li>
              해외 : 통신환경에 따라 상이 (국가 망 보급률, 통신사 품질에 영향)
              <span>당사 통계 : 동남아시아 94~97%</span>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>안드로이드 지원</td>
        <td>
          <ul>
            <li>Android 7.0이상 (API 25, Nougat)</li>
            <li>Kotlin 1.6이상(또는 Java 18 이상)</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>애플 iOS 지원</td>
        <td>
          <ul>
            <li>iOS 13 이상 (지원언어 : Swift)</li>
            <li>Swift 5.6.1 이상, Xcode : 13.4이상</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>웹 브라우저 지원</td>
        <td>
          <ul>
            <li>
              PC 지원 브라우저 : Chrome, Safari, Firefox, Whale, MS Edge
            </li>
            <li>
              Mobile 지원 브라우저 : iOS(Safari 12.2 이상, 웹뷰의 경우 14.3
              이상), 안드로이드(모든 브라우저 및 웹뷰)
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>추가 플랫폼 지원(예정)</td>
        <td>
          <ul>
            <li>Unity, React Native, Flutter 향후 지원시 사양 제공 예정</li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
  <p>
    * 상기 지원 기능 및 지원환경은 제품 업데이트 및 정책에 따라 변동될 수
    있으니, 최신 현황은 카카오엔터프라이즈 사업 담당자와 논의 후 유료 서비스
    도입 결정하시기 바랍니다.
  </p>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/base/_variables";
.feature-products {
  h3 {
    max-width: 1200px;
    margin: 0 auto;
    font-family: $fm-kakaoBigBold;
    font-size: 24px;
    color: $black;
  }

  table,
  p {
    max-width: 1200px;
    margin: 0 auto;
  }
  table {
    margin-top: 40px;
    border-collapse: collapse;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    font-family: $fm-kakaoSmall;
    font-size: 16px;
    thead {
      background-color: #f8f8f8;
      height: 56px;
      color: $black;
    }
    tr {
      border-bottom: 1px solid #e6e6e6;
      th {
        font-family: $fm-kakaoSmallBold;
        height: 56px;
        &:first-child {
          border-right: 1px solid #e6e6e6;
        }
      }
      td {
        padding: 16px 24px;
        color: $color-333;
        font-family: $fm-kakaoSmall;
        &:first-child {
          border-right: 1px solid #e6e6e6;
          text-align: center;
          padding: 16px 0;
          color: $black;
        }
        span {
          display: block;
          font-size: 14px;
          color: $color-666;
        }
        ul {
          li {
            padding-left: 9px;
            position: relative;
            line-height: 24px;
            &:before {
              display: block;
              position: absolute;
              left: -2px;
              top: 10px;
              content: "";
              width: 5px;
              height: 5px;
              background-color: #3c3e4a;
              border-radius: 4px;
            }
            ol {
              li {
                color: $color-666;
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  p {
    padding-top: 30px;
    font-size: 14px;
    color: $color-666;
  }
}
</style>
