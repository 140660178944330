<template>
  <svg
    width="126"
    height="63"
    viewBox="0 0 126 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="41.58"
      height="26.46"
      rx="4"
      transform="matrix(-1 0 0 1 97.0195 27.7197)"
      fill="#4384FF"
    />
    <path
      d="M78.6299 52.4195L85.4672 59.4174C86.0938 60.0586 87.1825 59.615 87.1825 58.7185L87.1825 43.666L78.6299 52.4195Z"
      fill="#4384FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.9386 6.2998C25.7294 6.2998 23.9386 8.09066 23.9386 10.2998V37.5798C23.9386 39.7889 25.7294 41.5798 27.9386 41.5798H38.6249V49.0076C38.6249 49.9042 39.7137 50.3477 40.3402 49.7065L48.2804 41.5798H77.8986C80.1077 41.5798 81.8986 39.7889 81.8986 37.5798V10.2998C81.8986 8.09067 80.1077 6.2998 77.8986 6.2998H27.9386Z"
      fill="white"
    />
    <path
      d="M55.4391 39.0603H45.7608L38.2666 46.7305C37.4771 47.5385 36.1053 46.9796 36.1053 45.85V39.0603H26.4591C23.6756 39.0603 21.4191 36.8038 21.4191 34.0203V8.82027C21.4191 6.03676 23.6756 3.78027 26.4591 3.78027H74.3391C77.1226 3.78027 79.3791 6.03676 79.3791 8.82027V34.0203C79.3791 36.8038 77.1226 39.0603 74.3391 39.0603H64.2591"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <rect
      x="27.7178"
      y="16.3799"
      width="43.9425"
      height="1.89"
      rx="0.945"
      fill="#333333"
    />
    <rect
      x="27.7178"
      y="26.46"
      width="24.0975"
      height="1.89"
      rx="0.945"
      fill="#333333"
    />
  </svg>
</template>
